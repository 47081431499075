import { useState } from 'react';

export default function SubscribeForm({ status, message, onValidated, onClose }) {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        firstName &&
        lastName &&
            email.indexOf("@") > -1 &&
            onValidated({
                MERGE0: email,
                MERGE1: firstName,
                MERGE2: lastName,
            });
    }
    return (
        <>
            {status === "sending" && (
                <div class="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
                    <span class="block sm:inline">Sending...</span>
                </div>
            )}
            {status === "error" && (
                <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <span class="block sm:inline" dangerouslySetInnerHTML={{ __html: message }}></span>
                </div>
            )}
            {status === "success" && (
                <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                    <span class="block sm:inline" dangerouslySetInnerHTML={{ __html: message }}></span>
                </div>
            )}
            {(status !== "sending" && status !== "error" && status !== "success") && 
            <div class="max-w-md mx-auto bg-white rounded px-4 pt-6 pb-2 mb-4">
              <div class="mb-4">
                <label class="block text-gray-700 font-bold mb-2" for="first-name">
                  First Name
                </label>
                <input onChange={(e) => setFirstName(e.target.value)} value={firstName} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="first-name" type="text" placeholder="John" />
              </div>
              <div class="mb-4">
                <label class="block text-gray-700 font-bold mb-2" for="last-name">
                  Last Name
                </label>
                <input onChange={(e) => setLastName(e.target.value)} value={lastName} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="last-name" type="text" placeholder="Doe" />
              </div>
              <div class="mb-6">
                <label class="block text-gray-700 font-bold mb-2" for="email">
                  Email Address
                </label>
                <input onChange={(e) => setEmail(e.target.value)} value={email} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="johndoe@example.com" />
              </div>
             <div >
              <button onClick={() => onClose()} className="mr-3 inline-flex text-[var(--red)]  border-1 py-2 px-6 focus:outline-none rounded text-lg">Cancel</button>
             <button onClick={(e) => handleSubmit(e)} className="ml-3 inline-flex text-white bg-[var(--red)] border-0 py-2 px-6 rounded text-lg">Subscribe</button>
             </div>
          </div>
            }
        </>
    )
}
