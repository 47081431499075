export default function WorkExpSection(props) {
    return <section className="text-gray-600 body-font bg-white" id='experience'>
        <div className="container px-5 py-20 mx-auto">
            <center>
                <h1 className='title-font sm:text-4xl text-3xl font-medium text-gray-900' >Experience</h1>
            </center>
            <div className="container px-5 py-24 mx-auto flex flex-wrap">
                {props.exps && props.exps.map((exp, index) => (
                    <div className="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
                        <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                        </div>
                        <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-red-500 text-white relative z-10 title-font font-medium text-sm">{index + 1}</div>
                        <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                            <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">{exp.title}</h2>
                                <p className="leading-relaxed">{exp.description}</p>
                            </div>
                        </div>
                    </div>))}
            </div>

        </div>
    </section>;
}