function Navbar(props) {
  return (
    <header
      className={`text-gray-600 body-font sticky top-0 bg-${props.bgColor} pt-2 pb-2 z-10`}
    >
      <marquee className="bg-red-500 m-0 p-0 sticky top-0 flex text-white text-lg font-bold">
        THIS IS MY ONLY WEBSITE. &nbsp;&nbsp; THIS IS MY ONLY WEBSITE.
        &nbsp;&nbsp; THIS IS MY ONLY WEBSITE. &nbsp;&nbsp; THIS IS MY ONLY
        WEBSITE. &nbsp;&nbsp; THIS IS MY ONLY WEBSITE. &nbsp;&nbsp; THIS IS MY
        ONLY WEBSITE. &nbsp;&nbsp; THIS IS MY ONLY WEBSITE. &nbsp;&nbsp; THIS IS
        MY ONLY WEBSITE. &nbsp;&nbsp; THIS IS MY ONLY WEBSITE. &nbsp;&nbsp; THIS
        IS MY ONLY WEBSITE. &nbsp;&nbsp; THIS IS MY ONLY WEBSITE. &nbsp;&nbsp;
        THIS IS MY ONLY WEBSITE. &nbsp;&nbsp;
      </marquee>
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
          <a
            href="/#"
            className="sm:mr-6 sm:ml-6 mr-3 ml-3 hover:text-[var(--red)]"
          >
            Home
          </a>
          <a
            href="/#books"
            className="sm:mr-6 sm:ml-6 mr-3 ml-3 hover:text-[var(--red)]"
          >
            Books
          </a>
          <a
            href="/#experience"
            className="sm:mr-6 sm:ml-6 mr-3 ml-3 hover:text-[var(--red)]"
          >
            Experience
          </a>
          <a
            href="/#blog"
            className="sm:mr-6 sm:ml-6 mr-3 ml-3 hover:text-[var(--red)]"
          >
            Blog
          </a>
          {props.showCollabs && (
            <a
              href="/#collabs"
              className="sm:mr-6 sm:ml-6 mr-3 ml-3 hover:text-[var(--red)]"
            >
              Collaborations
            </a>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
