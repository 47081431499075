// components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

// sections
import HeroSection from "./sections/HeroSection";
import BlogSection from "./sections/BlogSection";
import BooksSection from "./sections/BooksSection";
import WorkExpSection from "./sections/WorkExpSection";
import CollaborationSection from "./sections/CollaborationSection";

import { useEffect, useState } from "react";
import { db } from "./firebase/config";
import { collection, getDocs } from "firebase/firestore";
import GoogleFontLoader from "react-google-font-loader";

function App() {
  const [website, setWebsite] = useState(null);

  useEffect(() => {
    const ref = collection(db, "Website");
    getDocs(ref).then((snapshot) => {
      let sections = [];
      snapshot.docs.forEach((section) => {
        sections.push({ id: section.id, ...section.data() });
      });
      setWebsite(sections);
    });
  }, []);

  return (
    <div className="App">
      {website && (
        <>
          <GoogleFontLoader fonts={website[3]["fonts"]} />

          <Navbar
            bgColor={website[3]["bgColor"]}
            showCollabs={website[1]["cards"].length !== 0}
          />
          <HeroSection
            name={website[3]["name"]}
            subtitle={website[3]["subtitle"]}
            imageUrl={website[3]["imageUrl"]}
            email={website[3]["email"]}
            linkedin={website[3]["linkedin"]}
            facebook={website[3]["facebook"]}
            instagram={website[3]["instagram"]}
            twitter={website[3]["twitter"]}
            headingFont={website[3]["fonts"][0]["font"]}
            bgColor={website[3]["bgColor"]}
          />
          <BooksSection
            title={website[0]["title"]}
            books={website[0]["books"]}
          />
          <WorkExpSection exps={website[2]["exps"]} />
          <BlogSection />
          {website[1]["cards"].length !== 0 && (
            <CollaborationSection cards={website[1]["cards"]} />
          )}
          <Footer
            linkedin={website[3]["linkedin"]}
            facebook={website[3]["facebook"]}
            instagram={website[3]["instagram"]}
            twitter={website[3]["twitter"]}
          />
        </>
      )}
    </div>
  );
}

export default App;
