import MailchimpSubscribe from "react-mailchimp-subscribe";
import SubscribeForm from "../components/SubscribeForm";
import Modal from 'react-modal';
import { useState } from "react";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default function HeroSection(props) {
    const postUrl = `https://barbaravokatis.us12.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed. 
    }

    function closeModal() {
        setIsOpen(false);
    }

    return <section className={`text-gray-600 body-font bg-${props.bgColor}`} id='home'>
        <div className="container mx-auto flex px-5 pt-24 md:flex-row flex-col-reverse items-center">
            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                <h1 style={{ fontFamily: props.headingFont }} className="title-font sm:text-5xl text-3xl mb-4 font-medium text-gray-900">I'm <b>{props.name}</b></h1>
                <p className="mb-8 sm:text-2xl text-xl leading-relaxed">{props.subtitle}</p>
                <button className="inline-flex text-white bg-[var(--red)] border-0 py-2 px-6 focus:outline-none hover:bg-[var(--red)] rounded text-lg" onClick={openModal}>Join My Newsletter</button>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <MailchimpSubscribe
                        url={postUrl}
                        render={({ subscribe, status, message }) => (
                            <SubscribeForm
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                                onClose={closeModal}
                            />
                        )}
                    />
                </Modal>
            </div>
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-12 bg-center sm:p-20 p-14 md:p-12 xl:p-20 lg:p-20">
                {/* <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-12"> */}
                {/* <div className="bg-[url('gbg.svg')] bg-contain bg-no-repeat bg-center sm:p-20 p-14 md:p-12 xl:p-20 lg:p-20"> */}
                <img
                    className="rounded-3xl drop-shadow-2xl"
                    width={354}
                    height={354}
                    src={props.imageUrl}
                    alt={props.name} />
                {/* </div> */}
                {/* </div> */}
            </div>
        </div>
        <div className="container mx-auto px-5 pb-24 sm:pt-5 flex flex-col-reverse justify-center sm:justify-between sm:flex-row">
            <div className="self-center flex flex-row">
                <a href={props.linkedin} target='_blank' className="text-gray-500 hover:text-[var(--red)]" rel="noreferrer">
                    <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                        <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                        <circle cx="4" cy="4" r="2" stroke="none"></circle>
                    </svg>
                </a>
                <a href={props.facebook} target='_blank' className="ml-5 text-gray-500 hover:text-[var(--red)]" rel="noreferrer">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                </a>
                <a href={props.instagram} target='_blank' className="ml-5 text-gray-500 hover:text-[var(--red)]" rel="noreferrer">
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                        <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                        <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                    </svg>
                </a>

                <a href={props.twitter} target='_blank' className="ml-5 text-gray-500 hover:text-[var(--red)]" rel="noreferrer">
                    <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                    </svg>
                </a>
            </div>
            <div className="self-center hover:text-[var(--red)] sm:mb-0 mb-5">
                <a href={'mailto:' + props.email} target='_blank' rel="noreferrer">{props.email}</a>
            </div>
        </div>
    </section>;
}