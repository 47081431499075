import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC4BJ-VnzWvzhxiU4tzewJ6I2oLCSBfgUg",
  authDomain: "barbara-vokatis.firebaseapp.com",
  projectId: "barbara-vokatis",
  storageBucket: "barbara-vokatis.appspot.com",
  messagingSenderId: "179355199074",
  appId: "1:179355199074:web:0a2600da7359507ec6533b"
};

// init firebase
initializeApp(firebaseConfig);

// init firestore
const db = getFirestore();

export { db };