import { useEffect, useState } from "react";

const username = `bmvokatis`
const RSSUrl = `https://medium.com/feed/@${username}`;
const blogUrl = `https://medium.com/@${username}`
const RSSConverter = `https://api.rss2json.com/v1/api.json?rss_url=${RSSUrl}`;

export const getMediumData = async () => {
    
    try {
    const response = await fetch(RSSConverter);
    const data = await response.json();
    console.log(data);
    return data
    } catch(error){
        console.log(error)
    }
};

const getLatest3Posts = async () => {
    const posts = await getMediumData();
    let all3posts = [];
    for (let index = 0; index < 3; index++) {
        all3posts.push(posts.items[index]);
    }
    console.log(all3posts);
    return all3posts;
};

function formatDate(date) { 
    let msec = Date.parse(date);
    const d = new Date(msec);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${d.getDate()} ${months[d.getMonth()]}, ${d.getFullYear()}`;
}

function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '').substring(0,160);
}


export default function BlogSection(props) {

    const [allPosts, setAllPosts] = useState([]);

    useEffect(() => {
        getLatest3Posts().then(response => setAllPosts(response));
        
    }, []);

    return <section className="text-gray-600 body-font bg-[var(--light-white)]" id='blog'>
        <div className="container px-5 py-20 mx-auto">
            <center><h1 className='title-font sm:text-4xl text-3xl mb-16 font-medium text-gray-900' >My Recent Blog Posts</h1></center>
            <div className="flex flex-wrap -m-4">
                {allPosts && allPosts.map((post) => {
                    return <div key={post.guid} className="p-4 md:w-1/2 lg:w-1/3">
                    <div className="bg-white h-full border-2 border-gray-200 border-opacity-60 rounded-2xl overflow-hidden">
                        <img className="lg:h-60 md:h-60 w-full object-cover object-center" src={post.thumbnail} alt="blog" />
                        <div className="p-6">
                            <h1 className="title-font text-lg font-medium text-gray-900">{post.title}</h1>
                            <p className="text-gray-500 text-sm">By {post.author}</p>
                            <p className="text-gray-500 text-sm mb-1 mt-3">Published on {formatDate(post.pubDate)}</p>
                            <p className="leading-relaxed mb-3">{removeTags(post.description)}...</p>
                            <div className="flex items-center flex-wrap ">
                                <a href={post.link} target="_blank" className="text-red-500 inline-flex items-center md:mb-2 lg:mb-0" rel="noreferrer">Read More
                                    <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M5 12h14"></path>
                                        <path d="M12 5l7 7-7 7"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>;
                })}  
            </div>
            <center><a href={blogUrl} target="_blank" className="inline-flex text-white bg-[var(--red)] border-0 mt-16 py-2 px-6 focus:outline-none hover:bg-[var(--red)] rounded text-lg" rel="noreferrer">Read More</a></center>
        </div>
    </section>;
}