import Slider from 'react-slick';

const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
};

export default function BooksSection(props) {
    return <section className="text-gray-600 body-font bg-[var(--light-white)] overflow-hidden" id='books'>
        <div className="container px-5 py-20 mx-auto">
            <center>
                <h1 className='title-font sm:text-4xl text-3xl mb-8 font-medium text-gray-900' >My Books</h1>
            </center>
            <Slider {...settings}>
                {props.books.map(book => (<div>
                    <div className='bg-white sm:p-4 md:p-4 lg:p-8 p-4 rounded-2xl h-1/3'>
                        <div className=" mx-auto flex flex-wrap w-full">
                            <img alt="ecommerce" className="xl:1/3 lg:w-1/3 md:w-1/2 sm:w-full lg:h-auto object-cover object-center rounded-2xl" src={book.bookcover} />
                            <div className="xl:w-2/3 lg:w-2/3 md:w-1/2 md:pl-4 sm:w-full lg:pl-4 xl:p-10 lg:py-6 mt-6 lg:mt-0">
                                <h1 className="text-gray-900 sm:text-lg md:text-xl xl:text-2xl title-font font-medium mb-1">{book.name}</h1>

                                <p className="leading-relaxed sm:text-md md:text-sm xl:text-lg mb-2">{book.description}</p>

                                <div className="flex">
                                    <a href={book.buylink} target="_blank" className="flex ml-auto text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded" rel="noreferrer">Buy Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>))}
            </Slider>
        </div>
    </section>;
}