import React from 'react'

export default function CollaborationSection(props) {
    return (
        <section id="collabs" class="text-gray-600 body-font">
            <div class="container px-5 py-24 mx-auto">
                <center>
                    <h1 className='title-font sm:text-4xl text-3xl mb-8 font-medium text-gray-900' >Collaborations</h1>
                </center>

                <div class="flex flex-wrap -m-4">
                    {props.cards && props.cards.map((card) => {
                        return <div key={card} class="p-4 lg:w-1/4 md:w-1/2">
                            <div class="h-full flex flex-col items-center text-start">
                                <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src={card.imageUrl} />
                                <div class="w-full">
                                    <h2 class="title-font font-medium text-lg text-gray-900">{card.name}</h2>
                                    <h3 class="text-gray-500 mb-3">{card.occupation}</h3>
                                    <p class="mb-4">{card.description}</p>
                                    <div className="flex items-center flex-wrap ">
                                        <a href={card.link} target="_blank" className="text-red-500 inline-flex items-center md:mb-2 lg:mb-0" rel="noreferrer">Learn More
                                            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path d="M5 12h14"></path>
                                                <path d="M12 5l7 7-7 7"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>;
                    })}
                </div>
            </div>
        </section>
    )
}
